import {
  ColumnFilterType,
  NumericFilterModeName,
  TextFilterModeName,
} from '@shared/enums/filters/table-filters';
import { DataTableConfig, TableActions, TableColumnDisplay } from '../shared/interfaces/table';
import { format } from 'date-fns';
import { ClaimDetailResponse } from '@shared/interfaces/claim.interface';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';
import { DateFilterComponent } from '@shared/components/table/custom-filters/date-filter/date-filter.component';

export const ClaimsTableConfig: DataTableConfig<ClaimDetailResponse> = {
  endPoint: '/assetmanagement/projects/{project_id}/claims/',
  showCheckboxes: true,
  sortBy: 'name',
  fixedWidth: true,
  fixedMinWidth: '1400px',
  actionItems: [
    {
      label: 'Edit',
      value: TableActions.openEditMode,
      permission: AllGroupPermissions.CHANGE_CLAIM,
      icon: PrimeIcons.PENCIL,
    },
    {
      label: 'Delete',
      value: TableActions.deleteItem,
      permission: AllGroupPermissions.DELETE_CLAIM,
      icon: PrimeIcons.TRASH,
    },
  ],
  formatData: (data: ClaimDetailResponse[]) => {
    return data.map((item) => ({
      ...item,
      open_date: item.open_date && format(item.open_date, 'MM-dd-yyyy'),
      closing_date: item.closing_date && format(item.closing_date, 'MM-dd-yyyy'),
      equipmentName: item?.equipment?.name,
    }));
  },
  columns: [
    {
      title: 'Claim ID',
      name: 'code',
      displayType: TableColumnDisplay.value,
      width: '7%',
      hasColumnFilters: false,
      sortable: false,
      sortConfig: {
        sortKey: 'code',
      },
    },
    {
      title: 'Claim Title',
      name: 'name',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.link,
      width: '7%',
      sortable: false,
      hasColumnFilters: false,
      sortConfig: {
        sortKey: 'name',
      },
    },
    {
      title: 'Complaint',
      name: 'description',
      displayType: TableColumnDisplay.limitedCharacters,
      width: '10%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Equipment',
      name: 'equipmentName',
      displayType: TableColumnDisplay.value,
      width: '7%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Amount',
      name: 'warranty_amount_covered',
      displayType: TableColumnDisplay.value,
      width: '7%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Opening Date',
      name: 'open_date',
      displayType: TableColumnDisplay.value,
      width: '11%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'open_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'open_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
    {
      title: 'Close Date',
      name: 'closing_date',
      displayType: TableColumnDisplay.value,
      width: '11%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'closing_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'closing_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
    {
      title: 'Status',
      name: 'claim_status',
      displayType: TableColumnDisplay.taskStatus,
      width: '9%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'claim_status',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Status',
        fieldName: 'claim_status',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Type',
      name: 'claim_type',
      displayType: TableColumnDisplay.claimType,
      width: '9%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'claim_type',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Claim Type',
        fieldName: 'claim_type',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Priority',
      name: 'claim_priority',
      displayType: TableColumnDisplay.priority,
      width: '9%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'claim_priority',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Claim Priority',
        fieldName: 'claim_priority',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Assigned To',
      name: 'users',
      displayType: TableColumnDisplay.users,
      width: '8%',
      hasColumnFilters: false,
      sortable: false,
    },
  ],
};
